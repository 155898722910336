import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import { Box } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';

import CoursesBanner from '../../components/CoursesBanner';
import CoworkingBanner from '../../components/CoworkingBanner';
import CustomButton from '../../components/CustomButton';
import Metatags from '../../components/MetaTags/metatags';
import { COLORS, PATHS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const Home = () => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const Header = () => {
    return (
      <Box className={classes.homeWrapper}>
        <Box className={classes.homeContentWrapper}>
          <Box>
            <p className={fontClasses.sectionSubTitles}>{t('home.headerSubTitle')}</p>
            <h1 className={clsx(fontClasses.h1, classes.sectionTitle)}>{t('home.headerTitle')}</h1>
            <p className={clsx(fontClasses.p, classes.sectionText)}>{t('home.headerText')}</p>
            <Box className={classes.buttonsBox}>
              <CustomButton label={t('home.projectButton')} url={PATHS.projects} />
              <CustomButton
                label={t('home.contactButton')}
                type={'secondary'}
                url={PATHS.contact}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const About = () => {
    return (
      <Box className={classes.homeWrapper}>
        <Box className={classes.homeContentWrapper}>
          <Box className={classes.centerSection}>
            <p className={fontClasses.sectionSubTitles}>{t('home.aboutSubTitle')}</p>
            <h1 className={clsx(fontClasses.h2, classes.sectionTitleCenter)}>
              {t('home.aboutTitle')}
            </h1>
            <p className={clsx(fontClasses.p, classes.sectionText)}>
              {t('home.aboutText')}{' '}
              <Link className={fontClasses.linkText} to={PATHS.history}>
                {t('home.aboutLink')}
              </Link>
            </p>
          </Box>
          <Box className={classes.cardsBox}>
            <Box className={clsx(classes.cardAboutBox, classes.cardAboutBox1)}>
              <h1 className={clsx(fontClasses.h1, classes.cardTitle)}>{t('home.cardNumber1')}</h1>
              <p className={clsx(fontClasses.p, classes.cardText)}>{t('home.cardText1')}</p>
            </Box>
            <Box className={clsx(classes.cardAboutBox, classes.cardAboutBox2)}>
              <h1 className={clsx(fontClasses.h1, classes.cardTitle)}>{t('home.cardNumber2')}</h1>
              <p className={clsx(fontClasses.p, classes.cardText)}>{t('home.cardText2')}</p>
            </Box>
            <Box className={clsx(classes.cardAboutBox, classes.cardAboutBox3)}>
              <h1 className={clsx(fontClasses.h1, classes.cardTitle)}>{t('home.cardNumber3')}</h1>
              <p className={clsx(fontClasses.p, classes.cardText)}>{t('home.cardText3')}</p>
            </Box>
            <Box className={clsx(classes.cardAboutBox, classes.cardAboutBox4)}>
              <h1 className={clsx(fontClasses.h1, classes.cardTitle)}>{t('home.cardNumber4')}</h1>
              <p className={clsx(fontClasses.p, classes.cardText)}>{t('home.cardText4')}</p>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const Objectives = () => {
    return (
      <Box className={classes.homeWrapper}>
        <Box className={classes.objectivesContentWrapper}>
          <Box className={classes.objectivesTitleBox}>
            <Box>
              <p className={fontClasses.sectionSubTitles}>{t('home.objectiveSubTitle')}</p>
              <h2 className={clsx(fontClasses.h2, classes.objectivesSectionTitle)}>
                {t('home.objectiveTitle')}
              </h2>
            </Box>
          </Box>
          <Box className={classes.objectivesBox}>
            <Box className={classes.objectiveItemBox}>
              <Box className={classes.objectiveIconBox}>
                <LocalLibraryOutlinedIcon style={{ fontSize: '40px', color: COLORS.cyan }} />
              </Box>
              <Box className={classes.objectiveTextBox}>
                <p className={clsx(fontClasses.p, classes.objectiveTitle)}>{t('home.foment')}</p>
                <p className={clsx(fontClasses.p)}>{t('home.fomentText')}</p>
              </Box>
            </Box>
            <Box className={classes.objectiveItemBox}>
              <Box className={classes.objectiveIconBox}>
                <EmojiObjectsOutlinedIcon style={{ fontSize: '40px', color: COLORS.cyan }} />
              </Box>
              <Box className={classes.objectiveTextBox}>
                <p className={clsx(fontClasses.p, classes.objectiveTitle)}>{t('home.promote')}</p>
                <p className={clsx(fontClasses.p)}>{t('home.promoteText')}</p>
              </Box>
            </Box>
            <Box className={classes.objectiveItemBox}>
              <Box className={classes.objectiveIconBox}>
                <PsychologyOutlinedIcon style={{ fontSize: '40px', color: COLORS.cyan }} />
              </Box>
              <Box className={classes.objectiveTextBox}>
                <p className={clsx(fontClasses.p, classes.objectiveTitle)}>{t('home.develop')}</p>
                <p className={clsx(fontClasses.p)}>{t('home.developText')}</p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const Equality = () => {
    return (
      <Box className={classes.homeWrapper}>
        <Box className={clsx(classes.homeContentWrapper, classes.equalityContentWrapper)}>
          <Box className={classes.equalityTextWrapper}>
            <h2 className={clsx(fontClasses.h2, classes.equalityTitle)}>
              {t('home.equalityTitle')}
            </h2>
            <p className={clsx(fontClasses.p, classes.equalityContent)}>
              {t('home.equalityText')}
              <br></br>
              <a
                href='https://res.cloudinary.com/sealabs/image/upload/v1719255737/cemca.co.cr/website/COM-033-2024_VI_Edici%C3%B3n_2024_BPL_INAMU_znhmyv.pdf'
                className={fontClasses.linkText}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('home.equalityTextLink')}
              </a>
            </p>
          </Box>
          <Box className={classes.equalityImagesWrapper}>
            <img
              className={classes.equalityIcon}
              src={
                'https://res.cloudinary.com/sealabs/image/upload/v1719185868/cemca.co.cr/website/buenas-practicas_xsycme.png'
              }
            />
            <img
              className={classes.awardIcon}
              src={
                'https://res.cloudinary.com/sealabs/image/upload/v1719788993/cemca.co.cr/website/somos_CEMCA_nthcsb.svg'
              }
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const Partners = () => {
    const partnersList = [
      [
        {
          name: 'banco_nacional',
          url: 'https://res.cloudinary.com/sealabs/image/upload/v1703721819/cemca.co.cr/website/partners/bn_eofga1.png',
        },
        {
          name: 'camara_honduras',
          url: 'https://res.cloudinary.com/sealabs/image/upload/v1703721820/cemca.co.cr/website/partners/camaraH_jkjjtj.png',
        },
        {
          name: 'cde-ina',
          url: 'https://res.cloudinary.com/sealabs/image/upload/cemca.co.cr/website/partners/CDE_hjlrmu.png',
        },
        {
          name: 'comentarios_aldia',
          url: 'https://res.cloudinary.com/sealabs/image/upload/v1703721820/cemca.co.cr/website/partners/cometarios_a8bdwp.png',
        },
        {
          name: 'ecoins',
          url: 'https://res.cloudinary.com/sealabs/image/upload/v1703721820/cemca.co.cr/website/partners/ecoins_tkhqvz.png',
        },
        {
          name: 'foroMixto',
          url: 'https://res.cloudinary.com/sealabs/image/upload/cemca.co.cr/website/partners/foro_qgi5il.jpg',
        },
        {
          name: 'grameen',
          url: 'https://res.cloudinary.com/sealabs/image/upload/v1703721820/cemca.co.cr/website/partners/grameen_cmrxfv.png',
        },
        {
          name: 'ict',
          url: 'https://res.cloudinary.com/sealabs/image/upload/v1703721821/cemca.co.cr/website/partners/ict_bziiwp.png',
        },
      ],
      [
        {
          name: 'ina',
          url: 'https://res.cloudinary.com/sealabs/image/upload/v1703721821/cemca.co.cr/website/partners/ina_a4ukkv.png',
        },
        {
          name: 'ina_ane',
          url: 'https://res.cloudinary.com/sealabs/image/upload/cemca.co.cr/website/partners/ane-ina_fhhecp.png',
        },
        {
          name: 'inamu',
          url: 'https://res.cloudinary.com/sealabs/image/upload/cemca.co.cr/website/partners/inamu_losbki.png',
        },
        {
          name: 'ipg',
          url: 'https://res.cloudinary.com/sealabs/image/upload/cemca.co.cr/website/partners/ipg_xrg44w.png',
        },
        {
          name: 'meic',
          url: 'https://res.cloudinary.com/sealabs/image/upload/v1703721821/cemca.co.cr/website/partners/meic-1_kmu4ko.png',
        },
        {
          name: 'meic2',
          url: 'https://res.cloudinary.com/sealabs/image/upload/v1703721822/cemca.co.cr/website/partners/meic_ywpuq7.png',
        },
        {
          name: 'mtss',
          url: 'https://res.cloudinary.com/sealabs/image/upload/v1703721822/cemca.co.cr/website/partners/mtss_os8v0j.png',
        },
        {
          name: 'paniamor',
          url: 'https://res.cloudinary.com/sealabs/image/upload/v1703721822/cemca.co.cr/website/partners/paniamor_v8c3jn.png',
        },
      ],
      [
        {
          name: 'tec',
          url: 'https://res.cloudinary.com/sealabs/image/upload/v1703721823/cemca.co.cr/website/partners/tec_tkasra.png',
        },
        {
          name: 'ulicori',
          url: 'https://res.cloudinary.com/sealabs/image/upload/v1740848238/cemca.co.cr/website/partners/logo-ulicori_sdgsjk.png',
        },
        { name: '', url: '' },
        { name: '', url: '' },
        { name: '', url: '' },
        { name: '', url: '' },
        { name: '', url: '' },
        { name: '', url: '' },
      ],
    ];

    return (
      <Box className={classes.homeWrapper}>
        <Box className={classes.homeContentWrapper}>
          <Box>
            <p className={fontClasses.sectionSubTitles}>{t('home.partnerSubTitle')}</p>
            <h1 className={clsx(fontClasses.h2, classes.partnerTitle)}>{t('home.partnerTitle')}</h1>
            <p className={clsx(fontClasses.p, classes.sectionText)}>{t('home.partnerText')}</p>
          </Box>
          <Box className={classes.logosSection}>
            <Carousel
              autoPlay
              showStatus={false}
              showArrows={false}
              showIndicators={false}
              infiniteLoop
              stopOnHover
              interval={5000}
            >
              {partnersList.map((subPartenerList, index) => (
                <Box key={'partnerBox-' + index} className={classes.logoContentBox}>
                  {subPartenerList.map((subPartenerList, index2) => (
                    <Box key={'partnerBoxSub-' + index} className={classes.logoBox}>
                      <img className={classes.logoImg} src={subPartenerList.url} />
                    </Box>
                  ))}
                </Box>
              ))}
            </Carousel>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Metatags
        title='CEMCA'
        description='Empresarias comprometidas con el desarrollo económico y social de las mujeres.'
      />
      <Header />
      <CoworkingBanner />
      <About />
      <Objectives />
      <Equality />
      <CoursesBanner />
      <Partners />
    </>
  );
};

export default Home;
